
export default {
  name: 'news-card',
  props: {
    item: {
      type:Object,
      required: true,
    },
  },
  computed: {
    textPlug () {
      const firstText = this.item.content.elements[0].text;
      const firstSentenceEnd = firstText.indexOf('.');
      return firstSentenceEnd > 10
        ? firstText.slice(0, firstSentenceEnd)
        : firstText.slice(0, 100);
    },
  },
};
